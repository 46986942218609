// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-album-js": () => import("./../../../src/components/Album.js" /* webpackChunkName: "component---src-components-album-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-barrachin-js": () => import("./../../../src/pages/barrachin.js" /* webpackChunkName: "component---src-pages-barrachin-js" */),
  "component---src-pages-berrac-js": () => import("./../../../src/pages/berrac.js" /* webpackChunkName: "component---src-pages-berrac-js" */),
  "component---src-pages-christiane-et-guy-js": () => import("./../../../src/pages/christiane-et-guy.js" /* webpackChunkName: "component---src-pages-christiane-et-guy-js" */),
  "component---src-pages-components-js": () => import("./../../../src/pages/components.js" /* webpackChunkName: "component---src-pages-components-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marie-et-benjamin-index-js": () => import("./../../../src/pages/marie-et-benjamin/index.js" /* webpackChunkName: "component---src-pages-marie-et-benjamin-index-js" */),
  "component---src-pages-marie-et-benjamin-numeros-de-photos-js": () => import("./../../../src/pages/marie-et-benjamin/numeros-de-photos.js" /* webpackChunkName: "component---src-pages-marie-et-benjamin-numeros-de-photos-js" */)
}

